import { z } from "zod";
import {
  booleanComparisonExpSchema,
  orderBySchema,
  stringComparisonExpSchema,
  timestamptzComparisonExpSchema,
  uuidComparisonExpSchema,
  bilingualTextSchema,
  translationSchema,
  taxNumberSchema,
  nonEmptyStringSchema,
  stringNumbersSchema,
  stringNumbersSchemaRefinement,
  emptyStringSchema,
} from ".";
import {
  subscriptionsHistoryBoolExpSchema,
  subscriptionsListBoolExpSchema,
} from "./Subscriptions";
import sanitizeHtml from "sanitize-html";
import { usersBoolExpSchema } from "./Users";

// todo: make crnumber and taxnumber optional
export const setupOrganizationArgsSchema = z.object({
  name: nonEmptyStringSchema,
  currency: z.string(),
  crNumber: stringNumbersSchema
    .length(10)
    .superRefine(stringNumbersSchemaRefinement)
    .nullish(),
  taxNumber: taxNumberSchema,
  countryId: z.string(),
});

export type SetupOrganizationArgsSchema = z.infer<
  typeof setupOrganizationArgsSchema
>;

export const organizationsSetInputSchema = setupOrganizationArgsSchema
  .omit({ currency: true })
  .extend({
    address: z.string().nullable(),
    logoUrl: z.string().nullable(),
    enLogoUrl: z.string().nullable(),
    arLogoUrl: z.string().nullable(),
    posLogoUrl: z.string().nullable(),
    displayName: z.string(),
    email: z.string().nullable(),
    isArchived: z.boolean(),
    isStale: z.boolean(),
    ownerId: z.string().uuid(),
    phone: z.string().nullable(),
    website: z.string().nullable(),
    addressTranslations: translationSchema,
    displayNameTranslations: translationSchema,
    deletedAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    isActive: z.boolean(),
  })
  .partial();

export type OrganizationsSetInputSchema = z.infer<
  typeof organizationsSetInputSchema
>;

export const organizationsOrderBySchema = z.object({
  createdAt: orderBySchema.nullish(),
  isActive: orderBySchema.nullish(),
  name: orderBySchema.nullish(),
});

export type OrganizationsOrderBySchema = z.infer<
  typeof organizationsOrderBySchema
>;

export const organizationRolesBoolExpSchema = z.object({
  orgId: uuidComparisonExpSchema.nullish(),
});

export type OrganizationRolesBoolExpSchema = z.infer<
  typeof organizationRolesBoolExpSchema
>;

export const organizationRolesOrderBySchema = z.object({
  createdAt: orderBySchema.nullish(),
});

export type OrganizationRolesOrderBySchema = z.infer<
  typeof organizationRolesOrderBySchema
>;

const organizationRolesProperties = z.object({
  isEnabled: z.boolean().optional(),
});
export const organizationRolesAccessRulesProperties = z.object({
  create: z.boolean().optional(),
  delete: z.boolean().optional(),
  edit: z.boolean().optional(),
  confirm: z.boolean().optional(),
  view: z.boolean().optional(),
});

export type OrganizationRolesAccessRulesProperties = z.infer<
  typeof organizationRolesAccessRulesProperties
>;

export const organizationAccessRoles = z.object({
  sales: organizationRolesProperties.extend({
    customers: organizationRolesAccessRulesProperties,
    salesQuotations: organizationRolesAccessRulesProperties,
    invoices: organizationRolesAccessRulesProperties,
    receipts: organizationRolesAccessRulesProperties,
    creditNotes: organizationRolesAccessRulesProperties,
    other: z.object({
      allowPosPriceEditing: z.boolean().optional(),
      allowPosDiscountEditing: z.boolean().optional(),
    }),
  }),
  purchasing: organizationRolesProperties.extend({
    vendors: organizationRolesAccessRulesProperties,
    purchaseOrders: organizationRolesAccessRulesProperties,
    invoices: organizationRolesAccessRulesProperties,
    receipts: organizationRolesAccessRulesProperties,
    debitNotes: organizationRolesAccessRulesProperties,
  }),
  products: organizationRolesProperties.extend({
    productCategories: organizationRolesAccessRulesProperties,
    products: organizationRolesAccessRulesProperties,
    inventoryStores: organizationRolesAccessRulesProperties,
    inventoryConsumptions: organizationRolesAccessRulesProperties,
    inventoryStocktakes: organizationRolesAccessRulesProperties,
    inventoryTransfers: organizationRolesAccessRulesProperties,
  }),
  assets: organizationRolesProperties.extend({
    fixedAssets: organizationRolesAccessRulesProperties,
    fixedAssetTransactions: organizationRolesAccessRulesProperties,
  }),
  accounting: organizationRolesProperties.extend({
    accounts: organizationRolesAccessRulesProperties,
    manualJournals: organizationRolesAccessRulesProperties,
    generalReceipts: organizationRolesAccessRulesProperties,
  }),
  reports: organizationRolesProperties,
});

export type OrganizationAccessRoles = z.infer<typeof organizationAccessRoles>;

export const organizationRolesInsertInputSchema = z.object({
  orgId: z.string().uuid(),
  name: nonEmptyStringSchema,
  description: z.string().or(emptyStringSchema),
  accessRules: organizationAccessRoles,
});

export type OrganizationRolesInsertInputSchema = z.infer<
  typeof organizationRolesInsertInputSchema
>;

export const organizationRolesSetInputSchema =
  organizationRolesInsertInputSchema
    .omit({
      orgId: true,
    })
    .partial();

export type OrganizationRolesSetInputSchema = z.infer<
  typeof organizationRolesSetInputSchema
>;

export const organizationMembersOrderBySchema = z.object({
  createdAt: orderBySchema.nullish(),
});

export type OrganizationMembersOrderBySchema = z.infer<
  typeof organizationMembersOrderBySchema
>;

const organizationMembersBoolExpProperties = z.object({
  orgId: uuidComparisonExpSchema.nullish(),
  createdAt: timestamptzComparisonExpSchema.nullish(),
  acceptedAt: timestamptzComparisonExpSchema.nullish(),
  deletedAt: timestamptzComparisonExpSchema.nullish(),
});

export type OrganizationMembersBoolExpSchema = z.infer<
  typeof organizationMembersBoolExpProperties
> & {
  _or?: OrganizationMembersBoolExpSchema[] | null;
};

export const organizationMembersBoolExpSchema: z.ZodType<OrganizationMembersBoolExpSchema> =
  organizationMembersBoolExpProperties.extend({
    _or: z.lazy(() => organizationMembersBoolExpSchema.array().nullish()),
  });

const inviteMemberActionArgsSchemaProps = z.object({
  hasPosAccess: z.boolean(),
  inviteEmail: z.string().email(),
  isManager: z.boolean(),
  isReadonly: z.boolean(),
  memberRoles: z.array(z.string().uuid()),
  name: bilingualTextSchema.min(1).max(50),
  notes: bilingualTextSchema.or(emptyStringSchema),
  orgId: z.string().uuid(),
});

export const inviteMemberActionArgsSchema =
  inviteMemberActionArgsSchemaProps.superRefine((data, ctx) => {
    if (!data.isManager && !data.memberRoles.length) {
      ctx.addIssue({
        code: "too_small",
        type: "array",
        minimum: 1,
        inclusive: true,
        path: ["memberRoles"],
      });
    }
    return data;
  });

export type InviteMemberActionArgsSchema = z.infer<
  typeof inviteMemberActionArgsSchema
>;

export const inviteAcceptActionArgsSchema = z.object({
  inviteEmail: z.string().email(),
  inviteId: z.string().uuid(),
});

export type InviteAcceptActionArgsSchema = z.infer<
  typeof inviteAcceptActionArgsSchema
>;

export const organizationMembersSetInputSchema =
  inviteMemberActionArgsSchemaProps
    .omit({
      memberRoles: true,
      inviteEmail: true,
    })
    .extend({
      isSuspended: z.boolean(),
      deletedAt: z.string().datetime(),
    })
    .partial();

export type OrganizationMembersSetInputSchema = z.infer<
  typeof organizationMembersSetInputSchema
>;

export const organizationMemberRolesBoolExpSchema = z.object({
  memberId: uuidComparisonExpSchema,
});

export type OrganizationMemberRolesBoolExpSchema = z.infer<
  typeof organizationMemberRolesBoolExpSchema
>;

export const organizationMemberRolesInsertInputSchema = z.object({
  memberId: z.string().uuid(),
  roleId: z.string().uuid(),
});

export type OrganizationMemberRolesInsertInputSchema = z.infer<
  typeof organizationMemberRolesInsertInputSchema
>;

export const organizationReportsSettingsBoolExpSchema = z.object({
  orgId: uuidComparisonExpSchema.nullish(),
  readableId: stringComparisonExpSchema.nullish(),
});

export type OrganizationReportsSettingsBoolExpSchema = z.infer<
  typeof organizationReportsSettingsBoolExpSchema
>;

const organizationReportsSettingsConfig = z.object({
  exemptLocalTaxPurchasing: z.string().uuid().nullish(),
  exemptLocalTaxSales: z.string().uuid().nullish(),
  exportsTaxSales: z.string().uuid().nullish(),
  healthEduLocalTaxSales: z.string().uuid().nullish(),
  importsCustomsTaxPurchasing: z.string().uuid().nullish(),
  importsDeductibleTaxPurchasing: z.string().uuid().nullish(),
  mainTaxPurchasing: z.string().uuid().nullish(),
  mainTaxSales: z.string().uuid().nullish(),
  zeroLocalTaxPurchasing: z.string().uuid().nullish(),
  zeroLocalTaxSales: z.string().uuid().nullish(),
});

export type OrganizationReportsSettingsConfig = z.infer<
  typeof organizationReportsSettingsConfig
>;

export const organizationReportsSettingsInsertInputSchema = z.object({
  orgId: z.string().uuid(),
  readableId: z.string(),
  config: organizationReportsSettingsConfig,
});

export type OrganizationReportsSettingsInsertInputSchema = z.infer<
  typeof organizationReportsSettingsInsertInputSchema
>;

export const organizationReportsSettingsSetInputSchema =
  organizationReportsSettingsInsertInputSchema.pick({ config: true }).partial();

export type OrganizationReportsSettingsSetInputSchema = z.infer<
  typeof organizationReportsSettingsSetInputSchema
>;

export const organizationsBoolExpProperties = z.object({
  isActive: booleanComparisonExpSchema.nullish(),
  subscriptionsHistories: subscriptionsHistoryBoolExpSchema.nullish(),
  subscriptionsLists: subscriptionsListBoolExpSchema.nullish(),
  id: uuidComparisonExpSchema.nullish(),
  ownerId: uuidComparisonExpSchema.nullish(),
  isStale: booleanComparisonExpSchema.nullish(),
  isArchived: booleanComparisonExpSchema.nullish(),
  deletedAt: timestamptzComparisonExpSchema.nullish(),
  name: stringComparisonExpSchema.nullish(),
  users: usersBoolExpSchema.nullish(),
});

export type OrganizationsBoolExpSchema = z.infer<
  typeof organizationsBoolExpProperties
> & {
  _not?: OrganizationsBoolExpSchema | null;
  _and?: OrganizationsBoolExpSchema[] | null;
  _or?: OrganizationsBoolExpSchema[] | null;
};

export const organizationsBoolExpSchema: z.ZodType<OrganizationsBoolExpSchema> =
  organizationsBoolExpProperties.extend({
    _not: z.lazy(() => organizationsBoolExpSchema.nullish()),
    _and: z.lazy(() => organizationsBoolExpSchema.array().nullish()),
    _or: z.lazy(() => organizationsBoolExpSchema.array().nullish()),
  });

export const posSettingsSetInputSchema = z.object({
  printHeaderNotes: z
    .string()
    .transform((val) =>
      sanitizeHtml(val, { allowedTags: [], allowedAttributes: {} })
    )
    .nullish(),
  printFooterNotes: z
    .string()
    .transform((val) =>
      sanitizeHtml(val, { allowedTags: [], allowedAttributes: {} })
    )
    .nullish(),
});

export type PosSettingsSetInput = z.infer<typeof posSettingsSetInputSchema>;

export const transferOrgActionArgsSchema = z
  .object({
    orgId: z.string().uuid(),
    fromUserEmail: z.string().email(),
    toUserEmail: z.string().email(),
  })
  .superRefine((data, ctx) => {
    if (data.fromUserEmail === data.toUserEmail) {
      ctx.addIssue({
        code: "custom",
        message: "fromUserEmail and toUserEmail must be different",
        path: ["fromUserEmail", "toUserEmail"],
      });
    }
  });

export type TransferOrgActionArgsSchema = z.infer<
  typeof transferOrgActionArgsSchema
>;

export const transferInviteAcceptActionArgsSchema = z.object({
  inviteEmail: z.string().email(),
  inviteId: z.string().uuid(),
});

export type TransferInviteAcceptActionArgsSchema = z.infer<
  typeof transferInviteAcceptActionArgsSchema
>;
